@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'VistaSansAltLightRegular';
  src: url('./layout/fonts/vistasansaltlight-071211005EmigreWebOnly.eot');
  src: url('./layout/fonts/vistasansaltlight-071211005EmigreWebOnly.eot?#iefix') format('embedded-opentype'),
       url('./layout/fonts/vistasansaltlight-071211005EmigreWebOnly.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'VistaSansMedRegular';
  src: url('./layout/fonts/vistasansmed-071211005EmigreWebOnly.eot');
  src: url('./layout/fonts/vistasansmed-071211005EmigreWebOnly.eot?#iefix') format('embedded-opentype'),
       url('./layout/fonts/vistasansmed-071211005EmigreWebOnly.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
    font-family: 'VistaSansOT-Book';
    src: url('./layout/fonts/VistaSansOT-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@import url("https://fast.fonts.net/lt/1.css?apiType=css&c=e965d2c4-c572-405c-b5d1-e7b82eed2a06&fontids=5166196,5174727,5178775,5181757,5184440,5213942,5346498,5363961");
@font-face{
    font-family:"ITC Caslon_224 W05 Bold Italic";
    src:url("./layout/fonts/5166196/0683ae19-ded3-4c88-896c-d12edeb0b92e.woff2") format("woff2"),url("./layout/fonts/5166196/97a52502-1671-4208-bba7-0901c55c814c.woff") format("woff");
}
@font-face{
    font-family:"ITC Caslon_224 W05 Black";
    src:url("./layout/fonts/5174727/e9e5c897-63c9-4584-910c-6233fbaaa38e.woff2") format("woff2"),url("./layout/fonts/5174727/e3878c93-2a67-4cff-bec1-826d3d8a6c73.woff") format("woff");
}
@font-face{
    font-family:"ITC Caslon_224 W05 Medium";
    src:url("./layout/fonts/5178775/8f1c2833-afe6-44ac-8321-26ed1f8f5d9a.woff2") format("woff2"),url("./layout/fonts/5178775/d263b461-826a-4a15-987c-af60ae7e42a0.woff") format("woff");
}
@font-face{
    font-family:"ITC Caslon_224 W05 Book";
    src:url("./layout/fonts/5181757/177b461a-3e4c-4b17-92a1-10a71b37a91a.woff2") format("woff2"),url("./layout/fonts/5181757/00008adb-dd29-4303-945c-2c6bd95c31ec.woff") format("woff");
}
@font-face{
    font-family:"ITC Caslon_224 W05 Bold";
    src:url("./layout/fonts/5184440/aef0509c-cfe5-4c84-b686-cecfa8008fea.woff2") format("woff2"),url("./layout/fonts/5184440/ec6aa204-b89b-4a4d-a9ba-d1af92f5662a.woff") format("woff");
}
@font-face{
    font-family:"ITC Caslon_224 W05 Medium It";
    src:url("./layout/fonts/5213942/5a4816d3-ad99-4529-8c4b-eb0f223ff3fd.woff2") format("woff2"),url("./layout/fonts/5213942/4a47b73a-2ff5-4e48-93bb-23f1183f1a35.woff") format("woff");
}
@font-face{
    font-family:"ITC Caslon_224 W05 Book Italic";
    src:url("./layout/fonts/5346498/27d1ad29-d6d1-4294-aa16-c4ee0fc6fea2.woff2") format("woff2"),url("./layout/fonts/5346498/5c9b1698-1211-4fac-b16d-bae3d0f358f9.woff") format("woff");
}
@font-face{
    font-family:"ITC Caslon_224 W05 Black Itali";
    src:url("./layout/fonts/5363961/d444d6d9-0e6c-4494-a142-9603ba32ca7b.woff2") format("woff2"),url("./layout/fonts/5363961/9516345c-7213-448e-9a7b-b3ab2e65d89b.woff") format("woff");
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

body {
  margin: 0;
  font-family: 'VistaSansOT-Book';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.show-mobile {
  display: none;
}

.hide-mobile {
  display: block;
}


.step-container {
  padding: 60px;
}

.language-checkboxes .MuiFormControlLabel-root{
  align-items: start;
  margin-bottom: 15px;
}

.language-checkboxes .MuiCheckbox-root{
  padding: 0 9px;
}

.language-checkboxes .MuiTypography-body1 {
  letter-spacing: normal;
}

@media(max-width: 850px){
  .step-container {
    padding: 30px;
  }

  .white-container{
    padding: 1rem;
  }
}

.MuiFormControlLabel-root {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

@media(max-width: 850px){
  .show-mobile {
    display: block;
  }

  .show-mobile.items-center {
    font-family: 'VistaSansOT-Book';
    font-size: 18px;
    line-height: 27.5px;
    color: #5BB6D6;
  }

  .hide-mobile {
    display: none !important;
  }
}


.dropdown-input {
  margin-left: 15px;
}

button.blue-text-light {
  pointer-events: none;
  cursor: none;
}

.MuiButton-containedSizeLarge {
  padding: 10px 43px !important;
}

.MuiStep-completed,
.text-blue-500 {
  color: #5BB6D6;
}
.MuiPaper-root.MuiStepper-root.MuiStepper-horizontal > *:nth-last-child(1),
.MuiPaper-root.MuiStepper-root.MuiStepper-horizontal > *:nth-last-child(2) {
  display: none;
}

.logo{
  width: 250px;
  margin: 150px 0;

}

.text-4xl{
  font-family:"ITC Caslon_224 W05 Medium";
}

.add-more-button {
  width: 340px;
  height: 52px;
}
.app-container {
  max-width: 1024px;
  margin: auto;
}

.error-refferer {
  color: red
}
@media(max-width: 900){
  button.text-blue-500{
    font-size: 12px;
  }
}

@media(max-width: 800){
  button.text-blue-500{
    font-size: 0;
  }

  button.text-blue-500:after{
    content: 'o';
  }
}

.MuiFilledInput-underline:before{
  border-bottom: none !important;
}

.MuiFilledInput-input {
  background: #fff !important;
  border: 1px solid #c4c4c4 !important;
  border-radius: 5px !important;
}
.MuiSelect-filled.MuiSelect-filled {
  border-radius: 5px !important;
}

.other__input {
  padding: 0 10px;
}

.other__input .MuiOutlinedInput-input {
  padding: 5px 2px ;
}

div > .MuiOutlinedInput-input,
div.MuiInputBase-root legend {
  font-family: 'VistaSansOT-Book';
}

@media(max-width: 800px){
  .MuiInputLabel-outlined {
    font-size: 14px !important;
    margin-top: 2px;
    word-break: break-all;
    width: 90%;
  }

  .MuiInputLabel-filled {
    width: 92%;
    font-size: 14px;
    margin-top: -1px;
  }

  .buttons_bar button{
    width: 96%;
  }

  .health_header {
    width: 100%;
  }

  .health_header h2 {
      word-break: break-word;
  }
}

.information-box {
  background-color: #F4F9FD;
  border: 1px solid #BCDDF1;
  border-radius: 8px;
}

.information-box--error {
  background-color: #fff;
  border: 1px solid #EC6F6F;
  border-radius: 8px;
}

.blue-text{
  color: #5BB6D6;
}

.blue-text-light{
  color: #a8d4f0
}

.blue-border {
  border: 1px solid #5BB6D6
}

.blue-heading {
  font-family: "VistaSansMedRegular";
  font-size: 14px;
  line-height: 17.5px;
  color: #5BB6D6;
  text-transform: uppercase;
}

.grey-heading {
  font-family: "VistaSansMedRegular";
  font-size: 14px;
  line-height: 17.5px;
  color: #999995;
  text-transform: uppercase;
}

.MuiStepLabel-iconContainer {
  font-family: "VistaSansMedRegular";
}

.medication-checkboxes {
  flex-direction: row !important;
  justify-content: space-around;
}

.Mui-checked.MuiIconButton-colorPrimary + span {
  font-weight: bold;
}

.overlay {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  align-items: center;
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: black;
  opacity: .6;
}
